:root {
    --brandwhite: #d9d9d9;
    --brandnavy: #132437;
    --brandgreen: #46C973;

}

ul, input {
    padding: 0;
}

.container-root {
    height: 100vh;
    background-color: var(--brandnavy);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    /* font-family: "Comic Sans MS", "Comic Sans", cursive; */
}

.container-question {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: var(--brandwhite);
    border-radius: 15px;
    padding: 19px;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;
    
}

.container-question img {
    max-width: 100%;
    height: auto;  /* To maintain the aspect ratio */
    border-style: solid;
    border-color:#132437;
    border-width: 1px;
    border-radius: 6px;
    
}

.thumbnailImage {
    cursor: pointer;
}

.modalContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
}

.modalImage {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}


.container-question .question-text {
    padding: 15px 0; /* Adjusted padding for better spacing */
    font-size: 18px; /* Adjusted font size */
    font-weight: bold; /* Bold font for emphasis */
    color: #132437; /* Adjusted text color for better contrast */
}

.container-responses {
    height: 55vh;
    width: 100%;
    background-color: var(--brandwhite);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px 20px;
    box-sizing: border-box;
    overflow: auto; /* handle overflow */
    word-wrap: break-word; /* prevent overflow by breaking long words */
    
}

.responses{
    width: 100%;
    height: 80%;
}

.responses li {
    list-style-type: none;
    width: 100%;
    height: 25%
    
}

.response-button {
    width: 100%;
    background-color: transparent;
    border: none;
    text-align: center;
    border-bottom: 1px solid var(--brandnavy);
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.response-button.active {
    background-color: var(--brandgreen);
    color: white;
}

.scq-input {
    width: 100%;
    height: 40%;
    color: black;
    text-align: center;
    border-radius: 10px;
    background-color : #d1d1d1;
    font-size: 30px;
}


.container-validation {
    min-height: 15vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.container-validation button {
    width: 100%;
    height: 60%;
    background-color: var(--brandgreen);
    color: var(--brandwhite);
    border: none;
    border-radius: 15px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.answer {   
    color: var(--brandwhite);
    width: 100%;
    min-height: 60%;
    border-radius: 15px;
    overflow: auto; /* handle overflow */
    word-wrap: break-word; /* prevent overflow by breaking long words */
}

.answer p {
    font-size: calc(1rem + 1vw); /* Starts at 1rem and scales with viewport width */
    text-align: center; /* Center the text inside the <p> */
    margin: 0.5rem; /* Add some margin between the <p> elements */
}

.answer.wrong {
    background-color: red;
    color: black;
}

.answer.correct {
    background-color: var(--brandgreen);
}


.container-validation button:hover {
    background-color: darken(var(--brandgreen), 10%);
}

.container-validation button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}
