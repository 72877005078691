:root {
    --brand-color: #0c2435;
}

.container {
    position: relative; /* Make this the reference for absolutely positioned children */
    background-color: var(--brand-color)
}

.upper-block {
    height: 40vh;
    justify-content: center;
    align-items: center;
    font-size: 14vh;
    line-height: 100vh;
    display: flex;
}

.lower-block{
    height: 60vh;
    background-color: #e3e3e3;
    border-radius: 30px 30px 0 0;
    justify-content: center;
    align-items: center;
    display: flex;
    box-shadow: 0 1vh 5vh 0 var(--brand-color);
}

.image {
    border-radius: 50%;
    position: absolute;
    top: 42%; /* Position the image at the vertical center of the container */
    left: 50%; /* Position the image at the horizontal center of the container */
    transform: translate(-50%, -50%); /* Center the image */
    width: 50vw; /* Set a width for the image */
    height: auto; /* Maintain the aspect ratio of the image */
    box-shadow: 0 1vh 5vh 0 var(--brand-color);
}

.google-login-btn {
    box-shadow: 0 3px 5px 0 var(--brand-color);
    color: white;
    text-align: center;
    text-transform: capitalize;
    background-color: #102436;
    border-radius: 0.5vh;
    padding: .5vh 2vh; /* Combines padding-left and padding-right */
}

