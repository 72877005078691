:root {
    --brand-color: #0c2435;
    --brandwhite: #d9d9d9;
    --brandnavy: #132437;

}

.container {
    position: relative;
    /* Make this the reference for absolutely positioned children */
    background-color: var(--brandnavy)
}

.title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title h1 {
    color: var(--brandwhite);
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 5hv;
    margin-bottom: 0;
}

.upper-block {
    justify-content: center;
    align-items: center;
    display: flex;
    position: static;
}

.podium {
    position: relative;
    height: 30%;
    width: 90%;
    background-color: #DFDFDF;
    align-self: flex-end;
    bottom: 40px;
    border-top-left-radius: 12%;
    border-top-right-radius: 12%;
    display: flex;
    justify-content: start;
}

.podium p {
    color: #000;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

/* SECOND PLACE */

.second-place {
    height: 120%;
    width: 33%;
    background-color: #DFDFDF;
    align-self: flex-start;
    border-top-left-radius: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    flex-direction: column;
    text-align: center;
}

.second-place p {
    display: block;
    margin: 0;
}

.second-place p.name {
    color: #0c0600;
    margin-top: 5px;

    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.second-place p.score {
    color: #009BD6;
    margin-top: 5px;

    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.avatar-second {

    position: absolute;
    bottom: 11vh;
    width: 18vw;
    height: 18vw;
    border-radius: 50%;
    border: 3px #009BD6 solid;
}

/* FIRST PLACE */

.first-place {
    height: 150%;
    width: 34%;
    background-color: #D4D4D6;
    border-top-left-radius: 20%;
    border-top-right-radius: 20%;
    display: flex;
    justify-content: center;
    align-items: center;

    align-self: flex-end;
    flex-direction: column;
    text-align: center;
}

.first-place p {
    display: block;
    margin: 0;
}

.first-place p.name {
    color: #0c0600;
    margin-top: 5px;

    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.first-place p.score {
    color: #FB9639;
    margin-top: 5px;

    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.avatar-first {
    position: absolute;
    width: 20vw;
    height: 20vw;
    border-radius: 50%;
    border: 3px #FFAA00 solid;
    bottom: 15vh;
}

/* THIRD PLACE */

.third-place {
    height: 100%;
    width: 33%;
    background-color: #DFDFDF;
    border-top-right-radius: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    flex-direction: column;
    text-align: center;
}

.third-place p {
    display: block;
    margin: 0;
}

.third-place p.name {
    color: #0c0600;
    margin-top: 5px;

    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.third-place p.score {
    color: #00D95F;
    margin-top: 5px;

    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.avatar-third {

    position: absolute;
    bottom: 9vh;
    width: 18vw;
    height: 18vw;
    border-radius: 50%;
    border: 3px #00D95F solid;
}


.leaderboard {
    background-color: #e3e3e3;
    border-radius: 30px 30px 0 0;
    box-shadow: 0 1vh 5vh 0 var(--brandnavy);
    padding: 20px;
    overflow-y: scroll;
    height: 60vh;
}

.entry {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #ccc;
}

.avatar {
    flex: 0 0 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
}

.avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.player-info {
    flex: 1;
}

.player-info h2 {
    font-size: 20px;
    margin: 0;
    padding: 0;
}

.player-info p {
    font-size: 15px;
    color: #911b1b;
}

.score {
    font-size: 20px;
    font-weight: bold;
}